footer { padding-top: 80px; padding-bottom: 98px; margin: 0 50px;}
footer .footer-box { position: relative; background-image: url('../../assets/blog/forest.jpg'); background-repeat: no-repeat; background-position: center; object-fit: cover; height: 100%; width: 100%; background-size: cover; border-radius: 10px; padding: 10px 20px; border: 4px solid #000; z-index: 10;}
.footer-box .footer-opacity { position: absolute; background-color: rgba(255, 255, 255, 0.66); top: 0; left: 0; right: 0; bottom: 0; z-index: -1;}
/* footer .container { max-width: 1170px; margin: auto;} */
footer .footer-text { color: #000; font-size: 20px; font-family: 'Sree Krushnadevaraya', sans-serif; font-style: normal; font-weight: 400; line-height: 26px; letter-spacing: 1px;}
footer .footer-list-title { color: #000; font-size: 40px; font-family: 'Sree Krushnadevaraya', sans-serif; font-style: normal; font-weight: 400; line-height: normal;}
footer .footer-list a { display: block; padding: 4px 0; text-decoration: none; color: #000; font-size: 24px; font-family: 'Sree Krushnadevaraya', sans-serif; font-style: normal; font-weight: 400; }
footer .footer-list a .footer-location { color: #000; font-size: 18px; font-family: 'Sree Krushnadevaraya', sans-serif; font-style: normal; font-weight: 400; line-height: 20px; padding-bottom: 18px;}
.social-media-list { padding-top: 30px; padding-bottom: 90px;}
.social-media-list a { padding: 0 8px;}
.social-media-list a:first-child { padding-left: 0;}

.footer-logo { position: relative; overflow: hidden;}
.footer-logo .footer-small-logo { position: absolute; left: 115px; bottom: 10px;  border: 1px solid #000; border-radius: 50%; font-weight: bold; font-size: 10px; padding: 1px 5px; margin: 0;}

footer .explore-list { display: flex; flex-direction: column; align-items: center; justify-content: center;}

@media(max-width: 480px) {
    footer { padding-top: 40px; padding-bottom: 50px; margin: 0 10px;}
    footer .explore-list { align-items: start;}
    .social-media-list { padding-top: 30px; padding-bottom: 45px;}
    .footer-img-logo{ width:205px; height: 105px !important; margin-left: -35px;}
}