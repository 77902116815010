
  .tracking-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Libre Baskerville', sans-serif;
  }
  .modal.show .modal-dialog { max-width: 800px !important;}
  
  
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .step-content {
    font-size: 18px;
    margin-bottom: 30px;
  }
  
  .stepper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .stepper-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .stepper-button:hover {
    background-color: #0056b3;
  }
  
  .stepper-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .step-label {
    font-size: 18px;
    font-weight: bold;
  }
  