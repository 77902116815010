/* CSS for the dots */

.slick-dots {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
  }
  
  .slick-dots li {
    /* left: -90px !important; */
    display: inline-block;
    margin: 0 5px; /* Add margin between dots */
    width: 10px; /* Set the width of each dot */
    height: 10px; /* Set the height of each dot */
    /* background-color: #000; Set the color of each dot */
    /* border-radius: 50%; Make the dots circular */
    cursor: pointer;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
  }
  
  .slick-dots li.slick-active {
    /* background-color: #fff; Set the color of active dot */
  }
  .slick-dots ul{
    padding-left: 0px;
  }
  