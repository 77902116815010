.return-exchange-text-desktop {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  color: #ffffff;
  /* padding: 8px; */
  font-size: 12px;
  width: 100%;
  animation: slide-left 30s linear infinite;
  z-index: 100;
}
.marq{
  background-color: #000;
  padding-top: 8px;
  padding-bottom: 8px;
}
.return-exchange-text-desktop:hover {
  animation-play-state: paused;
}
@keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.header-container { overflow: hidden;}
header {
    position: sticky;
    position: -webkit-sticky;
    width: 100%;
    top: 0px;
    z-index: 100;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0 14px;
    transition: all 0.5s;
  }
  header .bras-btn {
    font-size: 32px;
    border: 0;
    background-color: transparent;
  }
  header .bras-btn i {
    color: #000;
  }
  header .list {
    display: block;
    position: fixed;
    width: 200px;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: #FFFFFF;
    z-index: 1000;
    transition: ease 0.5s;
  }
  header .list a {
    display: block;
    margin: 15px 15px;
  }
  header .list a {
    font-size: 16px;
    position: relative;
    text-decoration: none;
    color: #000000;
    border: 2px solid transparent;
    font-family: "Libre Baskerville";
    font-weight: 400;
    line-height: 20px;
    text-transform: capitalize;
    transition: ease 0.4s;
  }
  
  header .list a::after {content: ''; position: absolute; background-color: #FE9C3A; transform: scaleX(0); height: 2px; width: 100%; bottom: 0; left: 0; transition: ease 0.4s; will-change: transform; transform-origin: right; transition: transform 0.5s cubic-bezier(0.2, 0.6, 0.35, 1);} 
  header .list a:hover::after { transform: scaleX(1); transform-origin: left; }
  header .bras-btn {
    display: block;
  }
  header .list .buttons {
    display: block;
  }
  header .buttons {
    display: none;
  }
  header .buttons button {
    border: none;
    background-color: transparent;
    font-size: 14px;
    font-family: "Libre Baskerville";
    line-height: 20px;
    font-weight: 400;
    color: #000000;
  }
  
  header .overlay {
    background-color: rgba(0, 0, 0, 0.66);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 990;
    transition: ease 0.5s;
  }

.search-input {background-color: #F5F5F5; border-radius: 4px; padding: 7px 12px 7px 20px; max-width: 243px; position: relative;}
.search-input .search-suggestions { background-color: #F5F5F5; position: absolute; width: 100%;}
.search-suggestions li { padding: 10px 10px 10px 20px;}
.search-input input { color:  #000; font-size: 12px; font-family: 'Poppins', sans-serif; line-height: 18px;  border: none; outline: none; background-color: #F5F5F5; padding: 4px; padding-right: 34px;}
.search-input .search-icon { font-size: 22px;}
.logo {position: relative;}  
.logo .small-logo { position: absolute; left: 126px; bottom: 35px; border: 1px solid #000; border-radius: 50%; font-weight: bold; font-size: 10px; padding: 1px 5px; margin: 0;}

@media (min-width: 770px) {
  header .buttons a:nth-child(2) {
    margin: 17px;
  }
  header {
    padding: -1 122px;
    height: 91px;
    display: flex;
      justify-content: space-between;
      align-items: center;
    }
    header .bras-btn {
      display: none;
    }
    header .list a {
      display: inline-block;
      margin: 13px 8px;
    }
    header .list {
      position: static;
      width: unset;
      background-color: transparent;
      border-radius: 60px;
      margin-left: 0px;
      margin-right: 0px;
    }
    
  }
  
  header .cart-list { display: block; position: fixed; padding: 20px; width: 350px; right: 0; bottom: 0; top: 0; background-color: #ffffff; z-index: 1000; transition: ease 0.5s;}
  header .cart-overlay {background-color: rgba(0, 0, 0, 0.15); position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 990; transition: ease 0.}
  .cart-list h1 {font-family: 'Archivo' sans-serif; text-transform: uppercase; display: inline;}
  .cart-list button { background-color: transparent; border: none;}
  
  header .list a button { border: none; background-color: transparent; color: #000;}
  
  
  .wishlist-btn { position: relative;}
  .wishlist-btn .wishlist-text { position: absolute; right: -6px; top: -6px; font-weight: 600; color: red;}
  .return-exchange-text-mobile { display: none;}
  .logo a img { height: 90px; margin-left: 0;} 
.bras-box { display: none; }



@media(max-width: 768px) {
  .bras-box { display: flex; align-items: center; gap: 15px;}
  header { top: 0;}
  .logo .small-logo { position: absolute; left: 62px; bottom: 15px; border: 1px solid #000; border-radius: 50%; font-weight: bold; font-size: 8px; padding: 1px 5px; margin: 0;}
  .logo a img { height: 90px; width: 200px; margin-left: -35px; margin-top: 10px;} 
  .return-exchange-text-desktop , .marq { display: none;}
  .return-exchange-text-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    color: #f31010;
    /* padding: 8px; */
    font-size: 8px;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 100;
  }
}