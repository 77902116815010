.blog-detail-section { margin: 92px 0 0 0; background-color: #F6F6F6; padding: 50px 80px;}
.blog-detail-section .blog-title { padding-top: 40px; font-family: 'Libre Baskerville', sans-serif; font-weight: 700;}
.blog-detail-section .writer-blog { padding-top: 10px; font-family: 'Libre Baskerville', sans-serif; font-weight: 600;}
.blog-detail-section .blog-date { font-family: 'Libre Baskerville', sans-serif; font-weight: 600; padding-bottom: 40px;}
.blog-detail-section .blog-detail-text { font-family: 'Libre Baskerville', sans-serif; font-weight: 400; text-align: justify;}


@media(max-width: 480px) {
    .blog-detail-section { margin: 92px 0 0 0; background-color: #F6F6F6; padding: 30px 20px;}
    .blog-detail-section .blog-title { padding-top: 20px;}
    .blog-detail-section .blog-date { padding-bottom: 20px;}
}