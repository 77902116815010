.slick-dots {
  bottom: 10px; /* Adjust this value as needed */
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.slick-dots li {
  margin: 0 5px; /* Adjust the margin between dots as needed */
}
.slick-dots li button{
  background: none !important;
}

.slick-dots li button:before {
  font-size: 12px; /* Adjust the size of the dots */
  color: #fff; /* Adjust the color of the dots */
  opacity: 0.75; /* Adjust the opacity of the dots */
}

.slick-dots li.slick-active button:before {
  opacity: 1; /* Make the active dot fully opaque */
  color: #fff; /* Adjust the color of the active dot */
}
