@import url("https://fonts.googleapis.com/css2?family=Sree+Krushnadevaraya&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");

.hero-section {
  display: block;
  background-size: cover;
  height: 100vh;
  width: 100%;
  position: relative;
  z-index: 9;
  background-position: center;
  background-position: center;
  background-repeat: no-repeat;
}
.hero-section- {
  display: none;
  background-size: cover;
  height: 100vh;
  width: 100%;
  position: relative;
  z-index: 9;
  background-position: center;
  background-position: center;
  background-repeat: no-repeat;
}
/* .hero-section .hero-overlay { position: absolute; background-color: #000; opacity: 0.43; top: 0; left: 0; right: 0; bottom: 0; z-index: -1;} */
.hero-section .hero-content {
  position: absolute;
  left: 50%;
  top: 70%;
  transform: translate(-50%, -50%);
}
.hero-section- .hero-content {
  position: absolute;
  left: 50%;
  top: 70%;
  transform: translate(-50%, -50%);
}
/* .hero-section .slick-slider { z-index: 11; position: relative; top: 50%; left: 50%; transform: translate(-50%, -50%);} */
/* .hero-section .hero-content { position: relative; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 11;} */
/* .hero-section .slick-dots li button { height: 40px; width: 40px; color: #FFF;}
.hero-section .slick-dots li button:before { height: 40px; width: 40px; color: #FFF; opacity: .25;}
.hero-section .slick-dots li.slick-active button:before { height: 40px; width: 40px; opacity: 1;} */
.hero-slider {
  overflow: hidden;
}
.hero-slider .slick-dots {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
  bottom: 40px;
}
.hero-slider .slick-dots li {
  margin: 0 16px;
}
.hero-slider .slick-dots button {
  display: block;
  width: 1rem;
  height: 1rem;
  padding: 0;
  border: none;
  border-radius: 100%;
  background-color: #000 !important;
  text-indent: -9999px;
  opacity: 0.5;
}
.hero-slider .slick-dots li.slick-active button {
  background-color: #000 !important;
  opacity: 0.75;
}
.hero-section .slick-slider .slick-prev {
  left: 50px;
  z-index: 15;
}
.hero-section .slick-slider .slick-next {
  right: 50px;
  z-index: 15;
}
.hero-section .slick-slider .slick-prev:before {
  position: absolute;
  content: "";
  background-image: url("./assets/left-arrow.png");
  background-repeat: no-repeat;
  height: 54px;
  width: 54px;
  transition: all 0.5s;
}
.hero-section .slick-slider .slick-next:before {
  position: absolute;
  right: 25px;
  content: "";
  background-image: url("./assets/right-arrow.png");
  background-repeat: no-repeat;
  height: 54px;
  width: 54px;
  transition: all 0.5s;
}
.hero-section- .slick-slider .slick-prev {
  left: 50px;
  z-index: 15;
}
.hero-section- .slick-slider .slick-next {
  right: 50px;
  z-index: 15;
}
.hero-section- .slick-slider .slick-prev:before {
  position: absolute;
  content: "";
  background-image: url("./assets/left-arrow.png");
  background-repeat: no-repeat;
  height: 54px;
  width: 54px;
  transition: all 0.5s;
}
.hero-section- .slick-slider .slick-next:before {
  position: absolute;
  right: 25px;
  content: "";
  background-image: url("./assets/right-arrow.png");
  background-repeat: no-repeat;
  height: 54px;
  width: 54px;
  transition: all 0.5s;
}
.hero-content .hero-title {
  color: #fff;
  font-size: 54px;
  font-family: "Libre Baskerville", sans-serif;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 31px;
}
.hero-content .hero-text {
  color: #fff;
  font-size: 34px !important;
  font-family: "Libre Baskerville";
  font-weight: 400;
  text-transform: capitalize;
  margin-bottom: 10px;
}
.hero-content .shop-now-btn {
  border-radius: 5px;
  border: 1px dashed #000;
  background: #fff;
  padding: 10px;
}
.hero-content .shop-now-btn button {
  border: none;
  padding: 9px;
  background-color: transparent;
  color: #272727;
  font-size: 24px;
  font-family: "Sree Krushnadevaraya", sans-serif;
  text-transform: capitalize;
  margin-right: 20px;
}
.hero-content .shop-now-btn .shop-now-btn-arrow {
  transition: all 0.5s;
}
.hero-content .shop-now-btn:hover .shop-now-btn-arrow {
  transform: translate(10px);
}
.hero-section .hero-wrapper-1 {
  position: absolute;
  background-image: url("./assets/hero-layer.svg");
  height: 67px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  bottom: 0;
  z-index: 11;
}
.hero-section- .hero-wrapper-1 {
  position: absolute;
  background-image: url("./assets/hero-layer.svg");
  height: 67px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  bottom: 0;
  z-index: 11;
}
/* .hero-section .hero-wrapper-2 { position: absolute; background-image: url('./assets/hero-layer-2.png'); height: 108px; width: 105px; background-repeat: no-repeat; background-size: cover; object-fit: cover; bottom: 52px; z-index: 11;} */

@media (max-width: 480px) {
  .hero-section .hero-wrapper-1 {
    background-image: url("./assets/hero-layer.svg");
    height: 30px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    bottom: 0;
    z-index: 11;
  }
  .hero-section {
    height: 30vh;
    overflow: hidden;
    margin-top: 2px;
    display: none;
  }
  .hero-section .hero-content {
    width: 100%;
  }
  .hero-section- .hero-wrapper-1 {
    background-image: url("./assets/hero-layer.svg");
    height: 30px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    bottom: 0;
    z-index: 11;
  }
  .hero-section- {
    height: 70vh;
    overflow: hidden;
    margin-top: 2px;
    display: block;
    position: relative;
  }
  .hero-section- .hero-content {
    width: 100%;
  }
  .hero-content .hero-title {
    font-size: 22px;
    margin-bottom: 14px;
  }
  .hero-content .hero-text {
    font-size: 14px !important;
  }
  .hero-text1 {
    font-size: 10px;
  }
  .hero-title1 {
    font-size: 18px;
  }
 
}

.about-section {
  height: 740px;
  position: relative;
  overflow: hidden;
  background-color: #0000001f;
  margin-top: -36px;
}
.about-section .about-wrapper-1 {
  position: absolute;
  background-image: url("./assets/blog/wrapper5.jpeg");
  height: 594px;
  width: 420px;
  background-size: cover;
  z-index: -1;
  top: -96px;
  left: -200px;
  object-fit: cover;
  opacity: 0.55;
}
.about-section .about-wrapper-2 {
  position: absolute;
  background-image: url("./assets/blog/wrapper5.jpeg");
  height: 594px;
  width: 420px;
  background-size: cover;
  z-index: -1;
  bottom: -96px;
  right: -200px;
  object-fit: cover;
  opacity: 0.55;
}
.about-section .about-content {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
}
.about-content .about-title {
  color: #000;
  text-align: center;
  font-size: 40px;
  font-family: "Sree Krushnadevaraya", sans-serif;
  text-transform: capitalize;
  margin-bottom: 40px;
}
.about-content .about-text {
  max-width: 603px;
  margin: auto;
  color: #000;
  text-align: center;
  font-size: 18px;
  font-family: "Mulish", sans-serif;
  margin-bottom: 20px;
  font-weight: 600;
}
.about-content .about-text- {
  max-width: 603px;
  margin: auto;
  color: #000;
  text-align: center;
  font-size: 18px;
  font-family: "Mulish", sans-serif;
  margin-bottom: 20px;
  font-weight: 500;
}

.our-content {
  padding-bottom: 60px;
}
.our-category-section {
  background-color: #ffffff;
  position: relative;
  padding-bottom: 80px;
  display: none;
}
.our-category-section .our-wrapper-1 {
  position: absolute;
  top: 0;
  background-image: url("./assets/our-category/wrapper-1.png");
  height: 256px;
  width: 255px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  object-fit: cover;
}
.our-category-section .our-content {
  padding-top: 122px;
}
.our-content-title {
  color: #000;
  font-size: 40px;
  font-family: "Libre Baskerville", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
.our-card {
  background-image: url("./assets/our-category/card-wrapper.png");
  height: 406px;
  width: 406px;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  position: relative;
}
.our-card-img {
  position: absolute;
  top: 46%;
  left: 51%;
  transform: translate(-50%, -50%);
}
.shop-btn {
  position: absolute;
  top: 72%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border: 2px solid #000;
  width: 186px;
  font-size: 20px;
  font-family: "Sree Krushnadevaraya", sans-serif;
  text-transform: capitalize;
}
.view-all-btn {
  border-radius: 5px;
  border: 1px solid #dcdcdc;
  background: #272727;
  color: #fdfdfd;
  font-size: 20px;
  font-family: "Sree Krushnadevaraya", sans-serif;
  text-transform: capitalize;
  padding: 10px 20px;
  transition: all 0.5s;
  margin-top: 20px;
}
.view-all-btn:hover {
  color: #272727;
  background-color: #ffffff;
}

@media (max-width: 480px) {
  .view-all-btn {
    padding: 4px 14px;
  }
  .our-content {
    padding-bottom: 30px;
  }
  .about-content .about-text- {
    max-width: 380px;
    margin: auto;
    color: #000;
    text-align: center;
    font-size: 15px;
    font-family: "Mulish", sans-serif;
    margin-bottom: 20px;
    font-weight: 500;
  }
  .about-content .about-title {
    margin-bottom: 20px;
    font-size: 30px;
  }
  .about-section {
    height: 650px;
  }
  .about-section .about-wrapper-1 {
    position: absolute;
    background-image: url("./assets/blog/wrapper5.jpeg");
    height: 594px;
    width: 420px;
    background-size: cover;
    z-index: -1;
    top: -96px;
    left: -250px;
    object-fit: cover;
    opacity: 0.25;
  }
  .about-section .about-wrapper-2 {
    display: none;
  }
  .our-category-section {
    padding-bottom: 20px;
  }
  .our-category-section .our-content {
    padding-top: 64px;
  }
  .our-content-title {
    font-size: 24px;
  }
  .our-category-section .our-wrapper-1 {
    width: 100px;
    height: 134px;
  }
  .img-fluid-2 {
    width: 165px !important;
    margin-left: -6px !important;
  }
}

.arrivals-section {
  background: linear-gradient(180deg, #f6f6f6 0%, rgba(246, 246, 246, 0) 100%);
  position: relative;
  overflow: hidden;
  padding-bottom: 100px;
}
.arrivals-section .arrival-wrapper-1 {
  position: absolute;
  right: -150px;
  top: 0;
  background-image: url("./assets/arrivals/top-right.svg");
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  height: 280px;
  width: 280px;
}
.new-arrival-container {
  height: auto;
  overflow: hidden;
}
.arrival-card {
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  position: relative;
}
.arrival-card .arrival-card-img {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  height: 260px;
  border-radius: 10px;
  width: 260px;
  background-position: center;
  background-size: cover;
}
.arrival-card .arrival-card-img1 {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  height: 260px;
  border-radius: 10px;
  width: 260px;
  background-position: center;
  background-size: cover;
}
.arrival-card .like-btn {
  position: absolute;
  background-image: url("./assets/arrivals/Heart.png");
  height: 30px;
  width: 30px;
  right: 13px;
  top: 13px;
}
.arrival-card-content {
  padding: 22px 11px;
  padding-bottom: 46px;
}
.arrival-card-content .arrival-title {
  color: #272727;
  font-size: 20px;
  font-family: "Libre Baskerville", sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}
.arrival-card-content .brand-name {
  color: #272727;
  font-size: 18px;
  font-family: "Libre Baskerville", sans-serif;
  padding-right: 30px;
  margin-bottom: 12px !important;
}
.arrival-card-content .arrival-rating {
  color: #848484;
  font-size: 18px;
  font-family: "Libre Baskerville", sans-serif;
  padding-right: 6px;
  margin-bottom: 12px !important;
}
.arrival-card-content .arrival-price {
  color: #272727;
  font-size: 20px;
  font-family: "Libre Baskerville", sans-serif;
  font-weight: 700;
  padding-right: 12px;
}
.arrival-card-content .arrival-del {
  color: #848484;
  font-size: 16px;
  font-family: "Libre Baskerville", sans-serif;
  padding-right: 12px;
}
.arrival-card-content .arrival-off {
  color: #0a8200;
  font-size: 16px;
  font-family: "Libre Baskerville", sans-serif;
  font-weight: 700;
}
.arrival-card .add-to-cart-btn {
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #fff;
  font-size: 18.321px;
  font-family: "Libre Baskerville", sans-serif;
  border-radius: 5px;
  background: #272727;
  padding: 10px 12px;
}
.arrival-card .add-to-cart-btn a {
  text-decoration: none;
  color: #fff;
}
.arrivals-section .slick-slider {
  position: relative;
  max-width: 1170px !important ;
  margin: auto !important;
}
.arrivals-section .slick-dots {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
  bottom: 20px;
}
.arrivals-section .slick-dots li {
  margin: 0 8px;
}
.arrivals-section .slick-dots button {
  display: block;
  width: 1rem;
  height: 1rem;
  padding: 0;
  border: none;
  border-radius: 100%;
  background-color: #000 !important;
  text-indent: -9999px;
  opacity: 0.5;
}
.arrivals-section .slick-dots li.slick-active button {
  background-color: #000 !important;
  opacity: 1;
}
/* .arrivals-section .slick-slider .slick-prev { left: -100px; z-index: 15;}
.arrivals-section .slick-slider .slick-next { right: -100px; z-index: 15;}
.arrivals-section .slick-slider .slick-prev:before { position: absolute; content: ""; background-image: url('./assets/left-arrow.png'); background-repeat: no-repeat; height: 54px; width: 54px; transition: all 0.5s;}
.arrivals-section .slick-slider .slick-next:before { position: absolute; right: 10px; content: ""; background-image: url('./assets/right-arrow.png'); background-repeat: no-repeat; height: 54px; width: 54px; transition: all 0.5s;} */
.arrivals-section .arrival-wrapper-2 {
  position: absolute;
  left: -150px;
  bottom: 0;
  background-image: url("./assets/arrivals/bottom-left.svg");
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  height: 280px;
  width: 280px;
}

@media (max-width: 480px) {
  .arrivals-section {
    padding-bottom: 20px;
  }
  .arrivals-section .arrival-wrapper-1 {
    height: 180px;
    width: 180px;
    right: -100px;
    opacity: 0.55;
  }
  .arrivals-section .arrival-wrapper-2 {
    height: 180px;
    width: 180px;
    left: -100px;
    opacity: 0.55;
    z-index: -1;
  }
  .arrival-card .arrival-card-img {
    height: 150px;
    width: 150px;
  }
  .arrival-card .arrival-card-img1 {
    height: 150px !important;
    width: 150px !important;

  }
  .arrival-card {
    margin: 0 10px;
  }
}

.catalogues-section {
  background-color: #000;
  position: relative;
  overflow: hidden;
  padding-bottom: 172px;
}
/* position: absolute; background-image: url('./assets/'); height: 67px; width: 100%; background-repeat: no-repeat;  bottom: 0; z-index: 11; */
.catalogues-section .wrapper-1 {
  position: absolute;
  background-image: url("./assets/hero-layer.svg");
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  background-size: cover;
  height: 67px;
  width: 100%;
}
/* .catalogues-section .wrapper-2 {position: absolute; background-image: url('./assets/catalogues/wrraper2.svg'); background-repeat: no-repeat; background-size: cover; height: 294.06px; width: 294.06px; top: 78px; right: -200px;} */
.catalogues-section .catalogues-title {
  padding-top: 100px;
  color: #fff;
  font-size: 40px;
  font-family: "Libre Baskerville", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 65px;
}
.catalog-slider .catalog-card {
  position: relative;
  background-image: url("./assets/catalogues/circle-wrapper.png");
  height: 400px;
  width: 100%;
  background-repeat: no-repeat;
  object-fit: cover;
  background-position: center;
  background-size: cover;
}
.catalog-slider .catalog-card:hover {
  cursor: pointer;
}
.catalog-slider .circle-img {
  position: absolute;
  top: 16px;
  left: 20px;
  border-radius: 50%;
  height: 370px;
  width: 90%;
  object-fit: cover;
}

@media (max-width: 1440px) {
  .catalog-slider .catalog-card {
    height: 370px;
    width: 100%;
  }
  .catalog-slider .circle-img {
    top: 18px;
    left: 20px;
    height: 334px;
    width: 89%;
  }
}
@media (max-width: 1114px) {
  .catalog-slider .catalog-card {
    height: 312px;
    width: 100%;
  }
  .catalog-slider .circle-img {
    top: 18px;
    left: 18px;
    height: 274px;
    width: 89%;
  }
}
@media (max-width: 884px) {
  .catalog-slider .catalog-card {
    height: 350px;
    width: 100%;
  }
  .catalog-slider .circle-img {
    top: 18px;
    left: 20px;
    height: 314px;
    width: 89%;
  }
}
@media (max-width: 800px) {
  .catalogues-section .wrapper-1 {
    height: 32px;
  }
  .catalog-slider .catalog-card {
    height: 400px;
    width: 400px;
    max-width: 410px;
    margin: auto;
  }
  .catalog-slider .circle-img {
    top: 18px;
    left: 20px;
    height: 362px;
    width: 90%;
  }
}

.catalog-card .catalog-title {
  padding: 12px 0px;
  max-width: 280px;
  margin: 0;
  color: #ffffff;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-family: "Libre Baskerville", sans-serif;
  font-weight: 700;
  text-transform: capitalize;
  border-radius: 8px;
  border: 1px solid #fff;
  background: rgba(39, 39, 39, 0.1);
  backdrop-filter: blur(15px);
}
.catalog-card .catalog-shop-btn {
  max-width: 173px;
  margin: auto;
  position: absolute;
  border-radius: 5px;
  background: #fff;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 12px 22px;
}
.catalog-card .catalog-shop-btn button {
  color: #000;
  padding-right: 7px;
  font-size: 24px;
  font-family: "Sree Krushnadevaraya", sans-serif;
  font-weight: 400;
  text-transform: capitalize;
  border: none;
  background-color: transparent;
}
.catalog-card .catalog-shop-btn img {
  height: 22px;
  width: 22px;
  margin-top: 10px;
  transition: all 0.5s;
}

.catalogues-section .slick-slider {
  position: relative;
  max-width: 1196px !important ;
  margin: auto !important;
}
/* .catalogues-section .slick-slider .slick-prev { left: -100px; z-index: 15;}
.catalogues-section .slick-slider .slick-next { right: -100px; z-index: 15;}
.catalogues-section .slick-slider .slick-prev:before { position: absolute; content: ""; background-image: url('./assets/left-arrow.png'); background-repeat: no-repeat; height: 54px; width: 54px; transition: all 0.5s;}
.catalogues-section .slick-slider .slick-next:before { position: absolute; right: 10px; content: ""; background-image: url('./assets/right-arrow.png'); background-repeat: no-repeat; height: 54px; width: 54px; transition: all 0.5s;} */
.catalogues-section .slick-dots {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
  bottom: -30px;
}
.catalogues-section .slick-dots li {
  margin: 0 16px;
}
.catalogues-section .slick-dots button {
  display: block;
  width: 1rem;
  height: 1rem;
  padding: 0;
  border: none;
  border-radius: 100%;
  background-color: #fff !important;
  text-indent: -9999px;
  opacity: 0.5;
}
.catalogues-section .slick-dots li.slick-active button {
  background-color: #fff !important;
  opacity: 1;
}
/* .catalogues-section .wrapper-3 {position: absolute; background-image: url('./assets/catalogues/wrapper3.svg'); background-repeat: no-repeat; background-size: cover; width: 232px; height: 116px; bottom: 81px; left: -134px;} */
.catalogues-section .wrapper-4 {
  position: absolute;
  background-image: url("./assets/hero-layer.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 67px;
  width: 100%;
  bottom: 0;
}

@media (max-width: 480px) {
  .catalogues-section .wrapper-4 {
    height: 32px;
  }
  .catalog-slider .catalog-card {
    height: 300px;
    width: 300px;
    object-fit: cover;
    background-image: url("./assets/catalogues/mobile-circle-wrapper.png");
  }
  .catalog-slider .circle-img {
    height: 280px;
    width: 280px !important;
    top: 10px;
    left: 10px;
  }
  .catalogues-section {
    padding-bottom: 80px;
  }
  .catalogues-section .catalogues-title {
    font-size: 24px;
    padding-top: 50px;
    padding-bottom: 20px;
  }
  .catalogues-section .slick-dots {
    display: flex !important;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  /* .catalogues-section .wrapper-2 { height: 143.06px; width: 143.06px; top: 40px; right: -50px;} */
  /* .catalogues-section .wrapper-3 { width: 114px; height: 58px; bottom: 40px; left: -66px;} */
}

.shop-section {
  position: relative;
  background-color: #ffffff;
  overflow: visible;
  padding-bottom: 100px;
  overflow: hidden;
}
.shop-section .wrapper-1 {
  position: absolute;
  background-image: url("./assets/shop/shop-wrapper.svg");
  height: 235px;
  width: 500px;
  top: 0;
  left: 50%;
  background-repeat: no-repeat;
  transform: translate(-50%, 0);
  background-size: cover;
  object-fit: cover;
}
.shop-section .wrapper-2 {
  position: absolute;
  background-image: url("./assets/shop/wrapper3.svg");
  height: 372px;
  width: 372px;
  top: 436px;
  left: -38px;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}
.shop-section .wrapper-3 {
  position: absolute;
  background-image: url("./assets/shop/wrapper5.svg");
  height: 348px;
  width: 348px;
  bottom: 0;
  right: -157px;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}
.shop-section .shop-content {
  padding-top: 300px;
  padding-left: 135px;
  padding-right: 135px;
  position: relative;
  z-index: 11;
  padding-bottom: 50px;
}
.shop-content-title {
  color: #000;
  font-size: 40px;
  font-family: "Libre Baskerville", sans-serif;
  font-weight: 700;
  text-transform: capitalize;
  position: relative;
}
.shop-content-title::before {
  position: absolute;
  content: "";
  background-image: url("./assets/shop/wrapper4.png");
  height: 116px;
  width: 116px;
  left: 22%;
  top: 0px;
  transform: translate(-50%, -38px);
}
.shop-content-title::after {
  position: absolute;
  content: "";
  background-image: url("./assets/shop/wrapper4.png");
  height: 116px;
  width: 116px;
  right: 11%;
  top: 0px;
  transform: translate(-60%, -38px);
}
.shop-section .shop-items {
  padding-top: 30px;
  max-width: 1170px;
  margin: auto;
  position: relative;
  z-index: 10;
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr 1fr 1fr; */
  row-gap: 12px;
}
.shop-card {
  max-width: 270px;
  margin: auto;
  position: relative;
  transition: all 0.6s;
  overflow: hidden;
  padding: 2px;
}
/* .shop-card::before { content: ""; background-color: rgba(0, 0, 0, 0.30); top: 0; left: 0; right: 0; bottom: 0; position: absolute;} */
.shop-card .like-btn {
  position: absolute;
  background-image: url("./assets/arrivals/Heart.png");
  height: 30px;
  width: 30px;
  right: 17px;
  top: 17px;
}
.shop-card .liked-btn {
  position: absolute;
  background-image: url("./assets/arrivals/liked-btn.svg");
  height: 38px;
  width: 38px;
  right: 13px;
  top: 13px;
}
.shop-card .shop-img {
  width: 270px;
  height: 270px;
  border-radius: 10px;
  object-fit: cover;
  background-position: center;
}
.shop-card-content {
  position: absolute;
  padding: 22px 10px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.66);
  box-shadow: 3px 3px 30px 0px rgba(0, 0, 0, 0.15);
  min-width: 90%;
  margin: auto;
  transition: all 0.5s;
  bottom: 8px;
  left: 8px;
  right: 8px;
}
/* .shop-card:hover .shop-card-content { display: block;  bottom: 14px; left: 8px; right: 8px;} */
.shop-card-content .shop-title {
  color: #fff;
  font-size: 16px;
  font-family: "Libre Baskerville", sans-serif;
  font-weight: 700;
  margin-bottom: 0px;
  text-align: center;
}
.shop-card-content .brand-name {
  color: #272727;
  font-size: 18px;
  font-family: "Libre Baskerville", sans-serif;
  padding-right: 30px;
  margin-bottom: 12px !important;
}
.shop-card-content .shop-rating {
  color: #848484;
  font-size: 18px;
  font-family: "Libre Baskerville", sans-serif;
  padding-right: 6px;
  margin-bottom: 12px !important;
}
.shop-card-content .shop-price {
  color: #272727;
  font-size: 20px;
  font-family: "Libre Baskerville", sans-serif;
  font-weight: 700;
  padding-right: 12px;
}
.shop-card-content .shop-del {
  color: #848484;
  font-size: 16px;
  font-family: "Libre Baskerville", sans-serif;
  padding-right: 12px;
}
.shop-card-content .shop-off {
  color: #0a8200;
  font-size: 16px;
  font-family: "Libre Baskerville", sans-serif;
  font-weight: 700;
}
.category-scroll {
  width: 70%;
  padding-left: 120px;
}
.shop-select-category {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  border: 2px solid #000;
  margin-top: 10px;
  padding-bottom: 20px;
}
.wishlist-section {
  position: relative;
  background-color: #ffffff;
  overflow: hidden;
  padding-top: 100px;
  padding-bottom: 50px;
}
.wishlist-section .shop-items {
  padding-top: 30px;
  max-width: 1170px;
  margin: auto;
  position: relative;
  z-index: 10;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 12px;
}
.category-img {
  height: auto;
  width: 298px;
  object-fit: cover;
  background-size: cover;
  background-position: center;
}

@media (max-width: 768px) {
  .shop-section .wrapper-1 {
    opacity: 0.55;
  }
  .shop-section {
    padding-bottom: 0;
  }
  .shop-section {
    overflow: hidden;
  }
  .wishlist-section {
    padding-top: 60px;
    padding-bottom: 50px;
  }
  .shop-select-category {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  .shop-section .shop-items {
    grid-template-columns: 1fr 1fr;
  }
  .wishlist-section .shop-items {
    grid-template-columns: 1fr 1fr;
  }
  .shop-card .shop-img {
    width: 160px;
    height: 160px;
  }
  .shop-card-content {
    max-width: 184px;
    padding: 12px;
  }
  .shop-card-content .shop-title {
    font-size: 10px;
    margin-bottom: 4px;
  }
  .shop-card-content .brand-name {
    font-size: 14px;
    margin-bottom: 4px !important;
  }
  .shop-card-content .shop-rating {
    font-size: 14px;
  }
  .shop-card-content .shop-price {
    font-size: 12px;
    padding-right: 4px;
  }
  .shop-card-content .shop-del {
    font-size: 12px;
    padding-right: 4px;
  }
  .shop-card-content .shop-off {
    font-size: 12px;
  }
  .category-scroll {
    padding-left: 0px;
    padding-top: 60px;
  }
  .category-img {
    height: auto;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .shop-section .shop-content {
    padding-top: 220px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 20px;
  }
  .shop-content-title {
    font-size: 24px;
  }
  .shop-content-title::before {
    display: none;
  }
  .shop-content-title::after {
    display: none;
  }
  .shop-section .wrapper-2 {
    height: 200px;
    width: 200px;
    top: 350px;
    left: -38px;
  }
  .shop-section .wrapper-3 {
    height: 177px;
    width: 177px;
    bottom: 0;
    right: -90px;
  }
}

.customer-section {
  position: relative;
  overflow: hidden;
}
.customer-section .customer-content {
  padding-top: 70px;
}
.customer-section .wrapper-1 {
  position: absolute;
  background-image: url("./assets/customer/wrapper1.svg");
  height: 198px;
  width: 198px;
  background-size: cover;
  object-fit: cover;
}
.customer-section .customer-content-title {
  color: #000;
  font-size: 40px;
  font-family: "Libre Baskerville", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
}
.customer-section .customer-content-title::before {
  position: absolute;
  content: "";
  background-image: url("./assets/customer/wrapper2.png");
  height: 234px;
  width: 234px;
  left: 20%;
  top: 0px;
  transform: translate(-50%, -90px);
  background-size: cover;
  object-fit: cover;
}
.customer-section .customer-content-title::after {
  position: absolute;
  content: "";
  background-image: url("./assets/customer/wrapper2.png");
  height: 234px;
  width: 234px;
  right: 5%;
  top: 0px;
  transform: translate(-50%, -90px);
  background-size: cover;
  object-fit: cover;
}
.customer-section .customer-slider {
  margin-top: 36px;
  margin-bottom: 40px;
}
.customer-section .customer-card-border {
  border: 15px solid transparent;
  max-width: 976px;
  border-image-source: url("./assets/customer/card-border.png");
  border-image-repeat: round;
  border-image-slice: 30;
  border-image-width: 20px;
  margin: auto;
}
.customer-slider .customer-card {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  max-width: 946px;
  margin: auto;
  padding: 58px 32px 57px 32px;
  overflow: visible;
}
.customer-card .rating-text {
  color: #000;
  font-size: 18px;
  font-family: "Playfair Display", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 6px;
  padding-left: 18px;
}
.customer-card .customer-text {
  color: #272727;
  text-align: center;
  font-size: 20px;
font-family: "Sree Krushnadevaraya", sans-serif;
  font-style: normal;
  font-weight: 400;

  padding-top: 24px;
}

/* .customer-slider .slick-slider .slick-prev { left: 200px; z-index: 15;}
.customer-slider .slick-slider .slick-next { right: 200px; z-index: 15;}
.customer-slider .slick-slider .slick-prev:before { position: absolute; content: ""; background-image: url('./assets/left-arrow.png'); background-repeat: no-repeat; height: 54px; width: 54px; transition: all 0.5s;}
.customer-slider .slick-slider .slick-next:before { position: absolute; right: 10px; content: ""; background-image: url('./assets/right-arrow.png'); background-repeat: no-repeat; height: 54px; width: 54px; transition: all 0.5s;} */
.customer-slider .slick-dots {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
  bottom: -20px;
}
.customer-slider .slick-dots li {
  margin: 0 16px;
}
.customer-slider .slick-dots button {
  display: block;
  width: 1rem;
  height: 1rem;
  padding: 0;
  border: none;
  border-radius: 100%;
  background-color: #000 !important;
  text-indent: -9999px;
  opacity: 0.5;
}
.customer-slider .slick-dots li.slick-active button {
  background-color: #000 !important;
  opacity: 0.75;
}
.customer-slider .wrapper-3 {
  position: absolute;
  background-image: url("./assets/catalogues/wrapper3.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 232px;
  height: 116px;
  bottom: 81px;
  left: -134px;
}
.customer-slider .wrapper-4 {
  position: absolute;
  background-image: url("./assets/catalogues/wrapper1.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 37px;
  width: 100%;
  bottom: 0;
}

@media (max-width: 480px) {
  .customer-section .customer-content-title::before {
    display: none;
  }
  .customer-section .customer-content-title::after {
    display: none;
  }
  .customer-section .wrapper-1 {
    height: 128px;
    width: 128px;
    opacity: 0.45;
  }
  .customer-section .customer-content {
    padding-top: 0px;
  }
  .customer-section .customer-content-title {
    font-size: 24px;
  }
  .customer-section .customer-content-title::after {
    height: 120px;
    width: 120px;
    right: 0%;
    top: 80px;
  }
  .customer-slider .slick-slider .slick-prev {
    left: 0;
  }
  .customer-slider .slick-slider .slick-next {
    right: 0;
  }
}

.services-section {
  position: relative;
  background-color: #ffffff;
  overflow: hidden;
  padding-bottom: 180px;
}
.services-section .wrapper-1 {
  background-repeat: no-repeat;
  background-image: url("./assets/services/pngwing 2.svg");
  padding: 175px 0;
  background-position: center;
  background-size: contain;
}
.services-section .services-box {
  position: relative;
  z-index: 10;
  border-radius: 20px;
  background: #272727c9;
  max-width: 1170px;
  margin: auto;
  padding: 50px 0 80px 0;
}
.services-box .wrapper-left {
  position: absolute;
  background-image: url("./assets/services/wrapper-left.png");
  height: 243px;
  width: 123px;
  background-repeat: no-repeat;
  left: -123px;
}
.services-box .wrapper-right {
  position: absolute;
  background-image: url("./assets/services/wrapper-right.png");
  height: 243px;
  width: 123px;
  background-repeat: no-repeat;
  right: -123px;
}
.services-box .services-card-title {
  margin-bottom: 6px;
  color: #fff;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.services-box .services-card-text {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}
/* .services-section .wrapper-bottom { position: absolute; background-image: url('./assets/services/wrapper-bottom.svg'); width: 100%;  height: 303px; bottom: -50px; background-position: center; background-size: cover; object-fit: cover;} */
.services-section .wrapper-bottom {
  position: absolute;
  background-image: url("./assets/hero-layer.png");
  height: 67px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  bottom: 0;
  z-index: 11;
}

@media (max-width: 480px) {
  .services-section .wrapper-bottom {
    height: 32px;
  }
  .services-section {
    padding-bottom: 30px;
  }
  .services-section .wrapper-1 {
    padding: 20px 0;
  }
}

/* About Page CSS */

.about-hero {
  background-image: url("./assets/about-page/hero-bg.svg");
  width: 100%;
  height: 635px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  object-fit: cover;
  position: relative;
  margin-top: 91px;
}
.about-box {
  position: relative;
  padding: 74px 142px;
}
.about-img {
  position: relative;
  background-image: url("./assets/about-page/product2.png");
  height: 504px;
  width: 447px;
  background-repeat: no-repeat;
  object-fit: cover;
}
.about-img::before {
  position: absolute;
  content: "";
  top: -40px;
  left: -40px;
  background-image: url("./assets/about-page/wrapper-left.svg");
  width: 195px;
  height: 221px;
}
.about-img::after {
  position: absolute;
  content: "";
  bottom: -40px;
  right: -40px;
  background-image: url("./assets/about-page/wrapper-right.svg");
  width: 195px;
  height: 221px;
}

@media (max-width: 1024px) {
  .about-hero {
    margin-top: 50px;
    height: 120vh;
  }
  .about-img {
    position: relative;
    background-image: url("./assets/about-page/product2-mobile.png");
    height: 404px;
    width: 300px;
    background-repeat: no-repeat;
    object-fit: cover;
  }
  .about-img img {
    height: 404;
    width: 300px;
  }
  .about-box {
    position: relative;
    padding: 74px 35px;
  }
}

.about-title {
  color: #000;
  font-size: 40px;
  font-family: "Sree Krushnadevaraya", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 40px;
}
.about-text {
  color: #000;
  font-size: 18px;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.product-page {
  margin-top: 91px;
}
.product-item {
  border-bottom: 2px solid transparent !important;
  color: #000;
  padding: 20px 70px 16px;
  border: none;
  background-color: transparent;
  text-transform: uppercase;
  font-family: "Libre Baskerville";
  font-weight: 400;
  transition: all 0.5s;
}
.product-item:hover {
  border-bottom: 2px solid #000 !important;
}
.product-item-active {
  border-bottom: 2px solid #000 !important;
  color: #000;
  padding: 20px 70px 16px;
  border: none;
  background-color: transparent;
  text-transform: uppercase;
  font-family: "Libre Baskerville";
  font-weight: 400;
  transition: all 0.5s;
}
.category-section {
  background-color: #fff;
  max-width: 1235px;
  margin: auto;
  display: none;
  padding-bottom: 30px;
}
.category-box {
  width: 30%;
}
.category-box .category-title {
  color: #000;
  font-family: "Libre Baskerville", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
  padding: 10px 0;
}
.category-box .category-border {
  border-bottom: 1px solid #ebebeb;
  width: 100%;
  margin: 8px 0 16px 0;
}
.category-box .category-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.category-box .category-list li {
  color: #000;
  font-family: "Libre Baskerville", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 6px;
  letter-spacing: 1px;
  transition: all 0.5s;
  border-bottom: 1px solid transparent;
  margin-bottom: 16px;
  width: fit-content;
  cursor: pointer;
}
.category-box .category-list li:hover {
  border-bottom: 1px solid #000;
}
.category-box .category-list .active {
  border-bottom: 1px solid #000;
  color: #000;
  font-family: "Libre Baskerville", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 6px;
  letter-spacing: 1px;
  transition: all 0.5s;
  margin-bottom: 16px;
  width: fit-content;
  cursor: pointer;
}
.product-hero {
  background-image: url("./assets/product-img.jpg");
  height: 555px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  position: relative;
}
.product-hero .product-hero-title {
  position: absolute;
  font-size: 48px;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(1px);
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 20px;
  font-family: "Mulish", sans-serif;
}
.custom-dropdown {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-direction: row;
}
.selected-option {
  display: inline-block;
  border: 1px solid #e8e8e8;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 2.8px;
  line-height: 50px;
  cursor: pointer;
  width: 300px;
  text-align: center;
}
.dropdown-options {
  position: relative;
  width: 100%;
  left: 0;
  right: 0;
  background: #f0ebeb;
  z-index: 100;
  padding: 70px 10px;
  text-align: left;
  margin-top: -1px;
}
.dropdown-options-second {
  position: absolute;
  background: #f0ebeb;
  z-index: 100;
  margin-top: 0px;
  width: 299px;
}
.dropdown-options-second .option {
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 2.8px;
  font-weight: 400;
  line-height: 54px;
  cursor: pointer;
  text-align: center;
}
.dropdown-options .filter-option-title {
  border-bottom: 1px solid #ebebeb;
  display: block;
  font-size: 12px;
  color: #000;
  font-weight: 700 !important;
  overflow: hidden;
  letter-spacing: 2.8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 25px;
  padding-bottom: 10px;
  text-transform: uppercase;
  text-align: center;
}
.filter-option {
  max-height: 180px;
  overflow-y: auto;
  overflow-x: hidden;
  list-style: none;
  margin: 0;
  padding: 0;
}
.filter-option::-webkit-scrollbar {
  width: 4px;
}
.filter-option::-webkit-scrollbar-track {
  background: #dedede;
}
.filter-option::-webkit-scrollbar-thumb {
  background: #000;
}
.filter-option::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.filter-option li {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  font-family: "Mulish", sans-serif;
  margin-bottom: 10px;
  letter-spacing: 2px;
  justify-content: center;
}

@media (max-width: 768px) {
  .product-page {
    margin-top: 20px;
  }
  .custom-dropdown {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    flex-direction: column;
    align-items: center;
  }
  .product-item {
    padding: 16px 30px 8px 30px;
  }
  .category-section {
    padding-left: 20px;
    padding-bottom: 30px;
    padding-right: 20px;
  }
  .category-box {
    width: 50%;
  }
  .category-box .category-title {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
  }
  .about-text {
    font-weight: bold;
  }
  .category-box .category-list li {
    font-size: 12px;
  }
}

.apply-btn {
  border: 2px solid #dedede;
  width: 190px;
  color: #000;
  background-color: transparent;
  font-size: 11px;
  letter-spacing: 2.8px;
  text-transform: uppercase;
  font-family: "Mulish", sans-serif;
  height: 50px;
  font-weight: 400;
  transition: all 0.5s;
}
.clear-btn {
  border: 2px solid #dedede;
  border-left: 2px solid transparent;
  width: 190px;
  color: #000;
  background-color: transparent;
  font-size: 11px;
  letter-spacing: 2.8px;
  text-transform: uppercase;
  font-family: "Mulish", sans-serif;
  height: 50px;
  font-weight: 400;
  transition: all 0.5s;
}
.apply-btn:hover {
  border: 2px solid #666;
}
.clear-btn:hover {
  border: 2px solid #666;
}

/* Product Detail Page */

.product-detail-section {
  padding: 30px 0 30px 0;
  background-color: #f6f6f6;
}
.image-gallery-container {
  position: relative;
}
/* .image-gallery-container .slick-list { padding-left: 120px;}
.image-gallery-container .slick-slide { padding-left: 120px;} */

.image-gallery-container .slick-dots {
  display: flex !important;
  flex-direction: column;
  gap: 25px;
  left: 0px;
  width: unset;
  top: 50%;
  transform: translateY(-50%);
}
.image-gallery-container .slick-dots li {
  height: 92px;
  width: 92px;
  margin: 0;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-gallery-container .slick-dots .slick-active {
  border: 3px solid #3c4242;
  padding: 0px;


}
.image-gallery-container .slick-dots li img {
  height: 90px;
  width: 90px;
  object-fit: cover;
}

@media (max-width: 768px) {
  .css-9gnulq-MuiGrid-root {
    display: unset !important;
  }
  .image-gallery-container .slick-dots {
    flex-direction: row;
    top: unset;
    transform: translate(-50%, 90%);
    left: 50%;
    gap: 12px;
  }
  .image-gallery-container .slick-list {
    padding-left: 0;
  }
  .image-gallery-container .slick-dots .slick-active img {
    height: 52px;
  }
  .image-gallery-container .slick-dots li {
    height: 60px;
    width: 60px;
  }
  .image-gallery-container .slick-dots li img {
    height: 60px;
    width: 60px;
  }
  .css-1hkc9ol {
    display: unset !important;
  }
}

/* image gallery css */

.image-gallery {
  padding-left: 120px;
}
.image-gallery-play-button {
  display: none;
}
.image-gallery-fullscreen-button {
  display: none;
}
.image-gallery-thumbnail {
  width: 80px !important;
  height: 99px;
  overflow: hidden;
  margin-bottom: 10px;
}
.image-gallery-thumbnails {
  position: absolute;
  top: -670px;
  left: -120px;
  transform: translate(0%, 0%);
  margin-bottom: 10px;
}
.image-gallery-thumbnail + .image-gallery-thumbnail {
  display: block;
}
.image-gallery-right-nav {
  display: none;
}
.image-gallery-left-nav {
  display: none;
}
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  border: 2px solid #000000 !important;
}
.image-gallery-thumbnail:hover {
  border: 2px solid #000000 !important;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  height: 810px;
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
}
.image-gallery-image {
  height: 500px;
  width: 500px;
  object-fit: cover;
  border-radius: 6px;
  background-repeat: no-repeat;
}
.image-gallery-image img {
  height: 500px;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
  height: 128px;
  object-fit: cover;
}
.image-gallery-thumbnail .image-gallery-thumbnail-inner {
  height: 100%;
  width: 80px;
  object-fit: cover;
}

@media (max-width: 1024px) {
  .image-gallery-thumbnails {
    position: absolute;
    top: -672px;
    left: -120px;
    transform: translate(0%, 0%);
    margin-bottom: 10px;
  }
  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: auto;
  }
}
@media (max-width: 768px) {
  .image-gallery {
    padding-left: 0;
  }
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    width: 100%;
    height: 600px;
  }
  .image-gallery-image {
 width: 100%;
 /* height: 600px; */
  }
  /* .image-gallery-image img {
    height: 500px;
  } */
  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: auto;
  }
  .image-gallery-thumbnails {
    position: absolute;
    top: -572px;
    left: -120px;
    transform: translate(0%, 0%);
    margin-bottom: 10px;
  }
}
@media (max-width: 480px) {
  .product-detail-section {
    padding-top: 0px;
  }
  .image-gallery-container {
    padding-bottom: 120px;
  }
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    width: 100%;
    height: auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .image-gallery-thumbnails {
    position: absolute;
    top: 10px;
    left: 0px;
    transform: translate(0%, 0%);
    margin-bottom: 10px;
  }
  .image-gallery-image img {
    height: 300px !important;
  }
  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 96px;
  }
  .image-gallery-thumbnails .image-gallery-thumbnails-container {
    display: flex !important;
  }
  .add-to-cart-detail-btn {
    margin-top: 0px !important;
    margin-bottom: 20px !important;
  }
}

.product-details {
  padding-left: 0px;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  max-width: 30%;
}
@media (min-width: 1024px) {
  .product-details {
    padding-left: 80px;
  }
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    max-width: 30%;
  }
}
.product-title {
  color: #000;
  font-family: "Libre Baskerville", sans-serif;
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 24px;
}
.product-detail-name {
  color: #000000;
  font-family: "Libre Baskerville", sans-serif;
  font-weight: 500;
  font-size: 18px;
  margin: 0;
}
.product-detail-price {
  color: #000;
  font-family: "Libre Baskerville", sans-serif;
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 20px;
}
.product-detail-text {
  color: #000;
  font-family: "Libre Baskerville", sans-serif;
  font-weight: 400;
  letter-spacing: 0.7px;
  font-size: 16px;
  line-height: 20px;
}
.product-color p {
  color: #000;
  font-family: "Libre Baskerville", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
}
.product-color .color-btn {
  border: none;
  margin-right: 16px;
  padding: 14px 18px;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  color: #000;
  background-color: #fff;
  font-weight: 500;
}
.product-color .plush-btn {
  border: none;
  margin-right: 16px;
  padding: 10px 18px;
  border-radius: 4px;
  font-size: 16px;
  color: #666;
  background-color: #ececec;
  font-weight: 500;
}
.product-color input {
  border: none;
  margin-right: 16px;
  width: 100px;
  padding: 10px 18px;
  border-radius: 4px;
  font-size: 16px;
  color: #666;
  background-color: #ececec;
  font-weight: 500;
}
.product-color .active {
  color: #fff;
  background-color: #000;
}
.add-to-cart-detail-btn {
  color: #ffffff;
  background-color: #000000;
  padding: 12px;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 4px;
  border: none;
  transition: all 0.5s;
  margin-top: 30px;
}
.add-to-cart-detail-btn:hover {
  background-color: #fff;
  color: #000;
}
.css-heg063-MuiTabs-flexContainer {
  justify-content: space-between;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #000000 !important;
  border-bottom: none !important;
}
.details-tabs {
  margin-top: 70px;
}
.product-block-text {
  margin: 50px 0;
}
.tab-list {
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 6px 6px 0 0;
}
.tab-detail {
  padding: 24px 12px !important;
  border: 1px solid #ccc;
  background-color: #ffffff;
}

.blog-section {
  background-color: #dddada;
  padding-top: 91px;
  position: relative;
  overflow: hidden;
  z-index: 5;
}
.blog-container {
  position: relative;
  z-index: 10;
  padding-bottom: 100px;
}
.blog-section .blog-wrapper-1 {
  position: absolute;
  background-image: url("./assets/arrivals/top-right.svg");
  height: 303px;
  width: 319px;
  z-index: 10;
  background-size: cover;
  object-fit: cover;
  left: -24px;
  top: 80px;
}
.blog-section .blog-wrapper-2 {
  position: absolute;
  background-image: url("./assets/shop/wrapper5.svg");
  height: 303px;
  width: 319px;
  z-index: 10;
  background-size: cover;
  object-fit: cover;
  right: -180px;
  bottom: 0px;
}
.blog-section .blog-head {
  padding: 30px 0;
}
.blog-section .blog-card {
  background-color: #fff;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 20px;
}
.blog-section a {
  text-decoration: none;
}
.blog-section img {
  height: 300px;
  width: 300px;
}
.blog-section .blog-title {
  color: #000;
  font-family: "Libre Baskerville", sans-serif;
  font-weight: 700;
  font-size: 28px;
}
.blog-section .blog-text {
  color: #000;
  font-family: "Libre Baskerville", sans-serif;
  font-weight: 700;
  font-size: 16px;
}

@media (max-width: 780px) {
  .blog-section {
    background-color: #dddada;
    padding-top: 45px;
  }
  .blog-section .blog-head {
    padding: 30px 0;
  }
}

.profile-section {
  padding: 150px 0;
  background-color: #f6f6f6;
  position: relative;
  overflow: hidden;
}
.profile-section .wrapper-1 {
  position: absolute;
  background-repeat: no-repeat;
  background-image: url("./assets/customer/wrapper1.svg");
  height: 198px;
  width: 198px;
  bottom: -40px;
  padding: 0 0;
  background-position: center;
  background-size: contain;
}
.profile-section .wrapper-2 {
  position: absolute;
  background-repeat: no-repeat;
  background-image: url("./assets/shop/wrapper3.svg");
  height: 198px;
  width: 198px;
  bottom: -50px;
  right: 0;
  padding: 175px 0;
  background-position: center;
  background-size: contain;
}
.profile-section h1 {
  color: #000;
  font-family: "Libre Baskerville", sans-serif;
  font-size: 28px;
  font-weight: 600;
}
.profile-section .profile-letter {
  color: #fff;
  margin-top: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  height: 150px;
  width: 150px;
  margin-right: 40px;
}
.profile-section .profile-letter span {
  font-size: 40px;
  font-weight: 700;
}
.profile-name {
  margin-top: 40px;
  color: #000;
  font-family: "Libre Baskerville", sans-serif;
  font-size: 30px;
  font-weight: 600;
}
.profile-email {
  color: #000;
  font-family: "Libre Baskerville", sans-serif;
  font-size: 20px;
}
.profile-title {
  color: #000;
  font-family: "Libre Baskerville", sans-serif;
  font-size: 30px;
  font-weight: 600;
}
.profile-email span {
  font-weight: 600;
  font-size: 16px;
  padding-right: 50px;
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.order-details .dropdown {
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid #000;
  padding: 10px 20px;
  margin: 20px 0 10px 0;
}
.dropdown-title {
  font-size: 16px;
  font-weight: 500;
  font-family: "Libre Baskerville", sans-serif;
}
.address-title span {
  font-size: 20px;
  font-weight: 500;
  font-family: "Libre Baskerville", sans-serif;
  border-bottom: 2px solid #000;
}
.address-title {
  margin-bottom: 20px;
  font-family: "Libre Baskerville", sans-serif;
}
.profile-address {
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 10px;
  font-family: "Libre Baskerville", sans-serif;
}
.profile-input {
  border: 1px solid #000;
  border-radius: 6px;
  padding: 10px 10px;
  font-family: "Libre Baskerville", sans-serif;
}
.submit-btn {
  font-size: 20px;
  font-weight: 600;
  font-family: "Libre Baskerville", sans-serif;
  margin-top: 20px;
 width: 100%;
  border: 2px solid #000;
  color: #ffffff;
  background-color: #000;
  border-radius: 6px;
  transition: all 0.5s;
}
.submit-btn:hover {
  color: #000;
  border: 2px solid #000;
  background-color: transparent;
  background-color: #fff;
}

.order-list {
  position: fixed;
  background-color: #fff;
  height: 100vh;
  width: 350px;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  transition: all 0.5s;
  padding: 20px;
}
.order-details .overlay {
  background-color: rgba(0, 0, 0, 0.36);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  right: 0;
  z-index: 990;
  transition: ease 0.5s;
}

@media (max-width: 480px) {
  .profile-section .wrapper-1 {
    opacity: 0.45;
  }
  .profile-section .wrapper-2 {
    opacity: 0.45;
  }
  .profile-name {
    font-size: 24px;
  }
  .profile-section {
    padding: 85px 0;
  }
  .profile-section h1 {
    font-size: 22px;
  }
  .profile-name {
    font-size: 20px;
  }
  .profile-title {
    font-size: 22px;
  }
  .profile-email {
    font-size: 14px;
  }
}

.soap-list {
  display: flex;
}
.soap-list select {
  width: 200px;
}
.search-category .css-b62m3t-container .css-1fdsijx-ValueContainer {
  width: 200px !important;
}
.css-13cymwt-control {
  display: flex;
  width: 150px;
}
.category-title {
  font-family: "Libre Baskerville", sans-serif;
  padding: 20px 0;
  margin: 0;
}

.log-out-btn {
  font-size: 20px;
  font-weight: 600;
  font-family: "Libre Baskerville", sans-serif;
  margin-top: 20px;
  padding: 6px 24px;
  border: 2px solid #000;
  color: #ffffff;
  background-color: #000;
  border-radius: 6px;
  transition: all 0.5s;
}
.log-out-btn:hover {
  color: #000;
  border: 2px solid #000;
  background-color: transparent;
  background-color: #fff;
}

.cart-section {
  padding: 110px 0;
  position: relative;
  overflow: hidden;
  background-color: #0000001f;
}
.cart-section .wrapper-1 {
  position: absolute;
  background-image: url("./assets/blog/wrapper5.jpeg");
  height: 594px;
  width: 420px;
  background-size: cover;
  z-index: -1;
  top: 55px;
  left: -200px;
  object-fit: cover;
  opacity: 0.55;
}

@media (max-width: 768px) {
  .cart-section {
    padding: 60px 0;
  }
  .log-out-btn {
    padding: 4px 20px;
  }
  .cart-section .wrapper-1 {
    height: 394px;
    width: 320px;
    background-size: cover;
    z-index: -1;
    top: 25px;
    left: unset;
    right: -200px;
    object-fit: cover;
    opacity: 0.25;
  }
}

.cart-box {
  padding: 20px;
  border-radius: 6px;
}
.cart-box .cart-box-title {
  color: #000;
  font-family: "Libre Baskerville", sans-serif;
  font-size: 18px;
  margin-bottom: 10px;
}
.cart-box .cart-box-apply-box {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.cart-box .cart-input {
  color: #000;
  font-family: "Libre Baskerville", sans-serif;
  font-size: 18px;
  border-radius: 6px;
  outline: none;
  border: 1px solid #666;
  padding: 6px 10px;
  margin-right: 30px;
}
.cart-box .cart-box-text {
  border-bottom: 1px solid;
  padding-bottom: 10px;
  font-family: "Libre Baskerville", sans-serif;
  font-size: 14px;
}
.cart-box .apply-btn {
  background-color: #272727;
  color: #fff;
  font-family: "Libre Baskerville", sans-serif;
  font-size: 18px;
  border-radius: 6px;
  outline: none;
  border: none;
  padding: 5px 10px;
  margin-right: 30px;
  transition: all 0.5s;
}
.cart-box .apply-btn:hover {
  color: #000;
  background-color: #fff;
}
.payment-btn {
  background-color: #272727;
  color: #fff;
  border: 1px solid transparent;
  font-family: "Libre Baskerville", sans-serif;
  font-size: 18px;
  border-radius: 6px;
  outline: none;
  border: none;
  padding: 5px 10px;
  margin-right: 30px;
  transition: all 0.5s;
}
.payment-btn:hover {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}

.blog-img {
  text-align: center;
}
.blog-img img {
  height: 500px;
  width: 500px;
  margin: auto;
}
.cart-card {
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 20px;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
}
.cart-card .cart-card-img {
  height: 100px;
  width: 100px;
  margin-right: 20px;
}
.cart-card .cart-card-title {
  font-size: 20px;
  font-family: "Libre Baskerville", sans-serif;
  margin: 0;
  font-weight: 600;
  margin-bottom: 8px;
}
.cart-card .cart-card-text {
  font-size: 16px;
  font-family: "Libre Baskerville", sans-serif;
  margin: 0;
}
.cart-card .cart-card-text- {
  display: block;
  font-size: 16px;
  font-family: "Libre Baskerville", sans-serif;
  margin: 0;
}
.cart-card .cart-card-text-- {
  display: none;
  font-size: 16px;
  font-family: "Libre Baskerville", sans-serif;
  margin: 0;
}

@media (max-width: 480px) {
  .cart-box .cart-box-apply-box {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .cart-box .apply-btn {
    margin-top: 10px;
    font-size: 14px;
  }
  .payment-btn {
    font-size: 14px;
  }
  .cart-card .cart-card-text {
    font-size: 14px;
  }
  .cart-card .cart-card-text- {
    font-size: 14px;
    display: none;
  }
  .cart-card .cart-card-text-- {
    font-size: 14px;
    display: block;
  }
  .cart-card {
    flex-direction: column;
    align-items: start;
    gap: 14px;
  }
}

.tracking-section {
  margin-top: 91px;
}
.previous-btn {
  background-color: #000;
  color: #ffffff;
  padding: 8px 30px;
  border-radius: 10px;
  border: 2px solid #000;
  transition: ease 0.5s;
  margin: 20px 0;
  font-family: "Libre Baskerville";
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
}
.previous-btn:hover {
  border: 2px solid #000;
  background-color: transparent;
  color: #000;
}
.next-btn {
  background-color: #000;
  color: #ffffff;
  padding: 8px 30px;
  border-radius: 10px;
  border: 2px solid #000;
  transition: ease 0.5s;
  margin: 20px 0;
  font-family: "Libre Baskerville";
  font-size: 14px;
  font-weight: 600;
}
.next-btn:hover {
  border: 2px solid #000;
  background-color: transparent;
  color: #000;
}

.order-container {
  position: relative;
  height: 50vh;
  overflow-y: scroll;
}
.order-container::-webkit-scrollbar {
  display: none;
}
.order-bottom-item {
  position: fixed;
  bottom: 0;
}
.order-status {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
}
.order-status li {
  font-size: 20px;
  font-family: "Libre Baskerville", sans-serif;
  font-weight: 600;
  padding-left: 30px;
}
.active-order {
  font-size: 16px;
  position: relative;
  padding-left: 30px;
}
.active-order-2 {
  font-size: 16px;
  position: relative;
  padding-left: 30px;
}
.active-order::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #0a8200;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
}
.active-order-2::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #f00e19;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
}

.dropdown-content {
  background-color: rgba(0, 0, 0, 0.16);
  padding: 14px;
  border-radius: 10px;
}
.remove-cart {
  background-color: #272727;
  border: 1px solid transparent;
  color: #fff;
  font-family: "Libre Baskerville", sans-serif;
  font-size: 18px;
  border-radius: 6px;
  outline: none;
  border: none;
  padding: 5px 10px;
  margin-right: 30px;
  transition: all 0.5s;
}
.remove-cart:hover {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}

.table-container {
  border: none;
  border-collapse: unset;
  border-spacing: 0;
  width: 100%;
}
.table-thead tr {
  border-radius: 8px;
  border-bottom: 0.5px solid #cfcfcf;
  background: #6174dd;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  color: #fff;
  font-family: "Libre Baskerville", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  padding: 11px 20px;
  margin-bottom: 16px;
}
.table-tbody tr {
  border-radius: 8px;
  border: 0.5px solid #cfcfcf;
  background: #fafafa;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 6px 20px;
  margin-bottom: 8px;
}

.order-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.order-card {
  width: auto;
  padding: 12px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 3px;
}
.order-date {
  font-family: "Libre Baskerville", sans-serif;
  font-size: 12px;
  margin-bottom: 0;
  font-weight: 400;
}
.order-date span {
  font-family: "Libre Baskerville", sans-serif;
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 600;
}

.css-11qr2p8-MuiButtonBase-root-MuiButton-root {
  min-width: 38px !important;
}

.arrive-box {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-gap: 10px;
}

/* @media (max-width: 600px){
.shop-card .shop-img {
  width: 100%;
  height: 200px;
  padding: 10px;
}
.shop-card-content {
  width: 100%;
}
} */
.shop-cards{
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 10px;
}




@media (max-width: 480px) {
.customer-slider .customer-card {
  padding: 57px 18px 57px 18px;
}
.customer-card .customer-text {
  font-size: 15px;

}
}

.pd-mains{
  margin-left: 100px !important;
}
@media (max-width: 768px) {
  .pd-mains{
    margin-left: 0px !important;
  }
}
.bi-heart , .bi-cart3 , .bi-person-circle , .bi-whatsapp{
  font-size: 20px;
}
.bi-list{
  font-size: 25px;

}