.login {
 
  padding: 50px 0;
  background-image: url("../../assets/blog/login-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  position: relative;
  z-index: 10;
}
.forrget{
  width: 100%;
  background-color: #000;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 8px 30px;
}
.login .login-wrapper {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.66);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.login-1 {
  margin: 100px auto;
  padding: 50px 0;
  background-image: url("../../assets/blog/login-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  max-width: 1200px;
  border-radius: 50px;
}
.login-container {
  padding: 40px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  position: relative;
  backdrop-filter: blur(3px);
  max-width: 500px;
  margin: auto;
}

.login-container .login-title {
  font-family: "Libre Baskerville";
  font-size: 28px;
  font-weight: 700;
  color: #000;
  padding-bottom: 20px;
}
.login-input-container {
  margin-top: 30px;
}
.login-input-container .input-title {
  font-size: 16px;
  font-family: "Libre Baskerville";
  font-weight: 400;
  line-height: 24px;
  color: #000;
  padding-bottom: 10px;
}
.login-input-container .input-box {
  font-size: 16px;
  background-color: #f4f3f3;
  padding: 16px 12px;
  font-family: "Libre Baskerville";
  font-weight: 400;
  line-height: 24px;
  color: #000;
  outline: none;
  border-radius: 4px;
  margin-bottom: 20px;
  border: none;
}
.login .submit-btn {
  background-color: #000;
  color: #ffffff;
  padding: 8px 30px;
  border-radius: 10px;
  border: 2px solid #000;
  transition: ease 0.5s;
  margin: 20px 0;
  font-family: "Libre Baskerville";
  font-size: 14px;
  font-weight: 600;
}
.login .submit-btn:hover {
  border: 2px solid #000;
  background-color: transparent;
  color: #fff;
}
.login p {
  font-family: "Libre Baskerville", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  margin: 0;
}

.login-r-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.login-r-box a {
  font-family: "Libre Baskerville", sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  background-color: #000;
  padding: 5px 20px;
  border-radius: 6px;
  width: auto;
}

@media (max-width: 480px) {
  /* .login-r-box { display: flex; flex-direction: column; align-items: self-start; gap: 8px;} */
  .login {
    /* margin-top: 50px; */
    height: 100vh;
    padding: 10px;
  }
  .login-1 {
    margin: 50px 0;
    height: 800px;
  }
  .login-img img {
    height: 350px;
    width: 350px;
  }
  .login-container {
    padding: 20px;
    transform: translate(0, 100px);
  }
  .login-container .login-title {
    font-size: 24px;
    padding-bottom: 0px;
  }
  .login-input-container .input-box {
    font-size: 16px;
    background-color: #f4f3f3;
    padding: 8px 6px;
    margin-bottom: 10px;
  }
  .login p {
    font-size: 14px;
  }
  .login-r-box a {
    font-size: 14px;
    padding: 3px 14px;
    border-radius: 6px;
    width: auto;
  }
}

/* Profile Page Css */

/* 
.profile-hero-section { background-color: #ffffff; margin-top: 80px; padding: 50px 0;}
.profile-title { color: #fe9c3a; font-size: 40px; font-family: 'Libre Baskerville', sans-serif; font-weight: 600;}
.profile-circle { position: relative; height: 120px; width: 120px; background-color: #fe9c3a; border-radius: 50%; border: 4px solid #020289; margin: 20px 0;}
.profile-circle h2 { position: absolute; color: #020289; font-size: 50px; text-transform: uppercase; font-weight: 700; top: 50%; left: 50%; transform: translate(-50%, -50%);}
.profile-name { font-weight: 500; font-size: 16px; font-family: 'Libre Baskerville',sans-serif; text-transform: capitalize; padding-bottom: 5px;}
.profile-email span { font-weight: 500; font-size: 16px; font-family: 'Libre Baskerville',sans-serif;}
.profile-email { font-weight: 400; font-size: 16px; font-family: 'Libre Baskerville',sans-serif;}
.profile-btn .profile-login { font-size: 16px; font-weight: 500; font-family: 'Poppins', sans-serif;  margin-top: 20px; padding: 6px 24px; border: 2px solid #fe9c3a; color: #FFFFFF; background-color: #fe9c3a; border-radius: 6px; transition: all 0.5s;}
.profile-btn .profile-logout { font-size: 16px; font-weight: 500; font-family: 'Poppins', sans-serif;  margin-top: 20px; padding: 6px 24px; border: 2px solid #fe9c3a; color: #FFFFFF; background-color: #fe9c3a; border-radius: 6px; transition: all 0.5s;}
.profile-btn .profile-login:hover, .profile-btn .profile-logout:hover { color: #fe9c3a; border: 2px solid #fe9c3a; background-color: transparent;}

.order-details .dropdown { cursor: pointer; border-radius: 6px; border: 1px solid #000; padding: 10px 20px; margin: 20px 0 10px 0;}
.dropdown-title {font-size: 16px; font-weight: 500; }
.address-title span { font-size: 20px; font-weight: 500; border-bottom: 2px solid #000;}
.address-title {margin-bottom: 20px; }
.profile-address {font-size: 14px; font-weight: 500; padding-bottom: 10px;}
.profile-input { border: 1px solid #000; border-radius: 6px; padding: 10px 10px;}
.submit-btn { font-size: 20px; font-weight: 600; font-family: 'Poppins', sans-serif;  margin-top: 20px; padding: 6px 24px; border: 2px solid #fe9c3a; color: #FFFFFF; background-color: #fe9c3a; border-radius: 6px; transition: all 0.5s;}
.submit-btn:hover { color: #fe9c3a; border: 2px solid #fe9c3a; background-color: transparent;}

.order-list { position: fixed; background-color: #fe9c3a; height: 100vh; width: 250px; right: 0; top: 0; bottom: 0; z-index: 1000; transition: all 0.5s; padding: 20px;}
.order-details .overlay {
    background-color: rgba(0, 0, 0, 0.66);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    filter: blur(2px);
    -webkit-filter: blur(8px);
    z-index: 990;
    transition: ease 0.5s;
  } */
