.sign-in { padding: 50px 0; background-image: url('../../assets/blog/login-bg.jpg'); background-position: center; background-repeat: no-repeat; background-size: cover; object-fit: cover; position: relative; z-index: 10;}
.sign-in-1 { margin: 100px auto; padding: 50px 0; background-image: url('../../assets/blog/login-bg.jpg'); background-position: center; background-repeat: no-repeat; background-size: cover; object-fit: cover; max-width: 1200px; border-radius: 50px;}
.sign-in-wrapper { position: absolute; background-color: rgba(0, 0, 0, 0.66); top: 0; left: 0; right: 0; bottom: 0; z-index: -1; }
.sign-in-container {padding: 40px; border-radius: 10px;  background-color: rgba(255, 255, 255, 0.40); border-radius: 10px; backdrop-filter: blur(3px); position: relative; z-index: 10; max-width: 500px; margin: auto;}
.sign-in-container .sign-in-title { font-family: 'Libre Baskerville'; font-size: 28px; font-weight: 700; color: #000; padding-bottom: 20px;}
.sign-in-input-container { margin-top: 30px;}
.sign-in-input-container .input-title { font-size: 16px; font-family: 'Libre Baskerville'; font-weight: 400; line-height: 24px; color: #000; padding-bottom: 10px;} 
.sign-in-input-container .input-box { font-size: 16px; background-color: #F4F3F3; padding: 16px 12px; font-family: 'Libre Baskerville'; font-weight: 400; line-height: 24px; color: #000; outline: none; border-radius: 4px; margin-bottom: 20px; border: none;}  
.sign-in .submit-btn { background-color: #000; color: #ffffff; padding: 8px 30px; border-radius: 10px; border: 2px solid #000; transition: ease 0.5s; margin: 20px 0; font-family: 'Libre Baskerville'; font-size: 14px; font-weight: 600;}
.sign-in .submit-btn:hover { border: 2px solid #fff; background-color: transparent; color: #FFF;}
.sign-in p { font-family: 'Libre Baskerville', sans-serif; font-size: 16px; font-weight: 400; color: #000;}
.sign-in p a { font-family: 'Libre Baskerville', sans-serif; font-size: 16px; font-weight: 400; text-decoration: underline; color: #FFF; background-color: #000; padding: 5px 20px; border-radius: 6px;  font-weight: 600; cursor: pointer;}
.error-text { color: #DC3545; font-size: 12px !important;}


@media(max-width: 480px) {
    .sign-in { 
        padding: 10px;
     }
    .sign-in-container {padding: 20px; transform: translate(0, 80px);}
    .sign-in-container .sign-in-title {  font-size: 24px; padding-bottom: 0px;}
    .sign-in-input-container { margin-top: 14px;}
    .sign-in-input-container .input-box { font-size: 16px; background-color: #F4F3F3; padding: 8px 6px; margin-bottom: 10px;} 
    .sign-in p { font-size: 12px; font-weight: 400;}
    .sign-in-img img { height: 350px; width: 350px;}
}